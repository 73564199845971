@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./typography";

body {
  scroll-behavior: smooth;
}

.grecaptcha-badge {
  visibility: hidden;
}

#react-select-input {
  box-shadow: none;
}

.select.error-border {
  border: 1px solid red;
}

.share_button a {
  box-sizing: border-box;
  width: 200px !important;
  height: 40px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: none;
  color: white;
  text-transform: uppercase;
  font-weight: bold !important;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  text-align: center;
  align-items: center;
  font-size: 0 !important;
  padding: 10px 15px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  &::before {
    color: white;
    font-size: 18px;
    line-height: 35px;
  }
  &::after {
    color: white;
    font-size: 22px;
    margin-left: 10px;
    line-height: 35px;
    vertical-align: top;
    font-family: FontAwesome;
  }
}

.sp_em_large a {
  background: transparent !important;
  border: 1px solid #6400ff;
  &::before {
    color: #6400ff !important;
    content: "Send Email";
  }
  &::after {
    color: #6400ff !important;
    content: "";
  }
}

.sp_fb_large a {
  &::before {
    content: "Share";
  }
  &::after {
    content: "\f39e";
  }
}

.sp_tw_large a {
  &::before {
    content: "Tweet";
  }
  &::after {
    content: "\f099";
  }
}

.share_button a.whatsapp_large {
  width: 230px !important;
  background: #25d366;
  height: 60px !important;
  &::before {
    content: "Whatsapp";
  }
  &::after {
    content: "\f232";
  }
}

.sp_em_large.en a {
  &::before {
    content: "Send Email";
  }
}
.sp_em_large.de a {
  &::before {
    content: "E-Mail senden";
  }
}
.sp_em_large.es a {
  &::before {
    content: "Enviar correo electrónico";
  }
}
.sp_em_large.fr a {
  &::before {
    content: "Envoyer un courriel";
  }
}
.sp_em_large.pt a {
  &::before {
    content: "Enviar e-mail";
  }
}

.sp_fb_large.en a {
  &::before {
    content: "Share";
  }
}
.sp_fb_large.de a {
  &::before {
    content: "Facebook";
  }
}
.sp_fb_large.es a {
  &::before {
    content: "Share";
  }
}
.sp_fb_large.fr a {
  &::before {
    content: "Partager";
  }
}
.sp_fb_large.pt a {
  &::before {
    content: "Compartilhar";
  }
}

.sp_tw_large.en a {
  &::before {
    content: "Tweet";
  }
}
.sp_tw_large.de a {
  &::before {
    content: "Twitter";
  }
}
.sp_tw_large.es a {
  &::before {
    content: "Tweet";
  }
}
.sp_tw_large.fr a {
  &::before {
    content: "Tweeter";
  }
}
.sp_tw_large.pt a {
  &::before {
    content: "Tuitar";
  }
}

button:disabled {
  background-color: #1301014d !important;
  cursor: not-allowed;
}

button.sou-button:hover {
  background-color: #6400fff2;
}

button.prompt-yes:hover > img {
  transform: scale(1.2);
}

button.sou-button:active {
  outline: none;
  -moz-box-shadow: inset #6400fff2 0 3px 10px;
  -webkit-box-shadow: inset #6400fff2 0 3px 10px;
  box-shadow: inset #6400fff2 0 3px 10px;
}

button.donate-link {
  background-color: transparent;
  color: #172340;
  text-decoration: underline;
  text-underline-offset: 3px;
  &:focus {
    outline: auto;
    border-color: #172340;
  }
}

button.donate-link:disabled {
  background-color: transparent !important;
  color: #d1d5db;
  text-decoration: none;
}

.react-select__option--is-focused:not(.react-select__option--is-selected) {
  background-color: #6400fff2;
}

.donationContent {
  height: 1500px !important;
}

.petitionBody {
  p {
    margin-bottom: 1em;
    color: #414141;
  }
  span {
    color: #414141;
  }
}

input::placeholder {
  text-align: center;
}

button.AmountSelection__proceed-button,
button.MemberDetails__proceed-button {
  margin: 0 !important;
}

// Braintree
.BraintreeCardFields__card-number [type="number"] {
  background-color: rgb(249, 250, 251) !important;
  padding: 0;
}

.BraintreeCardFields__hosted-field.braintree-hosted-fields-focused {
  border: 1.5px solid #6400ff;
}
.BraintreeCardFields__hosted-field.braintree-hosted-fields-focused
  + .BraintreeCardFields__card-type {
  color: #6400ff;
}
.BraintreeCardFields__hosted-field.braintree-hosted-fields-invalid {
  border: 1.5px solid red;
}
.BraintreeCardFields__hosted-field.braintree-hosted-fields-invalid
  + .BraintreeCardFields__card-type {
  display: none;
}

#consent [type="radio"]:checked {
  background-image: none;
}
#consent [type="radio"] {
  color: #d1d5db;
}

#close-modal {
  box-shadow: 0px 5px 10px #0003;
}

.fa-brands:hover {
  color: #6400ff;
}

#cookie-preference-icon {
  width: 30px;
  height: 30px;
  box-shadow: 0px 20px 40px -10px rgba(0, 0, 0, 0.4); /* Increased shadow values */
  // box-shadow: 0px 12px 25px -6px rgba(0, 0, 0, 0.25);
}

.PhoneInputInput {
  width: 100%;
  padding: 1.25rem 1rem;
  background-color: rgba(243, 244, 246, 1);
  border-radius: 0.25rem;
  border: none;
  padding-bottom: 0.5rem;
  color: #000;
  outline: none;
  box-shadow: none;
  min-height: 54px;
}

.PhoneInputInput[disabled] {
  background-color: #475569;
  cursor: not-allowed;
}

// .PhoneInputInput focus
.PhoneInputInput:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #6400ff;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  border-color: #6400ff;
}

.PhoneInputCountry {
  background-color: rgba(243, 244, 246, 1);
  padding: 10px 10px;
}

.PhoneInputCountryIcon--border {
  border-color: #6400ff;
}

aside {
  bottom: 0;
  top: unset;
}

.apple-pay-button {
  display: inline-block;
  width: 100%;
  height: 4rem;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: donate; /* Use any supported button type. */
  --apple-pay-button-width: 440px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 5px;
  --apple-pay-button-padding: 5px 0px;
}

.apple-pay-button-black {
  -apple-pay-button-style: black;
}
.apple-pay-button-white {
  -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}
